<template>
  <form-wizard
    ref="refFormWizard"
    color="#7367F0"
    :title="null"
    :subtitle="null"
    finish-button-text="Submit"
    back-button-text="Previous"
    hide-buttons
    class="checkout-form-wizard steps-transparent"
  >
    <!-- account detail tab -->
    <tab-content
      title="Cart"
      icon="feather icon-shopping-cart"
    >
      <e-commerce-checkout-step-cart
        :items="checkoutDetails.items"
        :payment-details="checkoutDetails.payment"
        @next-step="formWizardNextStep"
      />
    </tab-content>

    <!-- address -->
    <tab-content
      title="Address"
      icon="feather icon-home"
    >
      <e-commerce-checkout-step-address
        :address-details="checkoutDetails.address"
        @next-step="formWizardNextStep"
      />
    </tab-content>

    <!-- social link -->
    <tab-content
      title="Payment"
      icon="feather icon-credit-card"
    >
      <e-commerce-checkout-step-payment
        :payment-details="checkoutDetails.payment"
        :items="checkoutDetails.items"
        @next-step="submitForm"
      />
    </tab-content>
  </form-wizard>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import { ref } from '@vue/composition-api'
import store from '@/store'
import ecommerce from '@/service/warehouse/ecommerce'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ECommerceCheckoutStepPayment from './ECommerceCheckoutStepPayment.vue'
import ECommerceCheckoutStepCart from './ECommerceCheckoutStepCart.vue'
import ECommerceCheckoutStepAddress from './ECommerceCheckoutStepAddress.vue'
// eslint-disable-next-line import/no-cycle
import { useEcommerce } from '../useEcommerce'

export default {
  components: {
    // 3rd Party
    FormWizard,
    TabContent,
    // SFC
    ECommerceCheckoutStepCart,
    ECommerceCheckoutStepAddress,
    ECommerceCheckoutStepPayment,
  },
  setup() {
    const refFormWizard = ref(null)
    const products = ref([])
    const formWizardNextStep = () => {
      refFormWizard.value.nextTab()
    }
    const { removeProductFromCart } = useEcommerce()
    const removeProductFromCartClick = product => {
      removeProductFromCart(product.id)
        .then(() => {
          const productIndex = products.value.findIndex(p => p.id === product.id)
          products.value.splice(productIndex, 1)

          store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', products.value.length)
        })
    }
    // ? This is just dummy details
    const checkoutDetails = ref({
      address: {
        fullName: '',
        phone: '',
        email: '',
        country: null,
        city: null,
        area: null,
      },
      payment: {
        cvv: '',
        payment_id: '',
        total: '',
        count: '',
      },
      items: [],
    })
    // const submitForm = async () => {
    //   console.log('omer')
    //   const docStaticCode = 'EC'
    //   const user = JSON.parse(localStorage.getItem('userData'))
    //   const resultData = await ecommerce.postAxios({
    //     date: '',
    //     total_price: checkoutDetails.value.payment.total,
    //     document_type_id: 3,
    //     vendor_set_id: user.id,
    //     warehouse_id: 1,
    //     description: 'ome',
    //     status_id: 1,
    //     current_document_no: docStaticCode,
    //     document_no: docStaticCode,
    //     model_id: 3,
    //     user_id: user.id,
    //     items: checkoutDetails.value.items,
    //   }).catch(error => error)
    //   console.log(resultData)
    //   setTimeout(() => {
    //     if (resultData.data) {
    //       // this.$refs.formName.reset()
    //       // this.showError = false
    //       this.$router.push({ path: '/' })
    //     //   this.showToast('Notification', 'CheckCircleIcon', 'Sales Request saved successfully.👍', 'success')
    //     // } else {
    //     //   this.showToast('Error', 'XCircleIcon', 'Sales Request Not saved', 'danger')
    //     // }
    //     // this.showOverLay = false
    //   }, 1000)
    // }
    return {
      refFormWizard,
      formWizardNextStep,
      removeProductFromCartClick,

      // Dummy Details
      checkoutDetails,

      // submitForm,
    }
  },
  methods: {
    showToast(title, icon, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      })
    },
    async submitForm() {
      // console.log(this.checkoutDetails)
      const docStaticCode = 'EC'
      const user = JSON.parse(localStorage.getItem('userData'))
      const resultData = await ecommerce.postAxios({
        date: '',
        total_price: this.checkoutDetails.payment.total,
        document_type_id: 3,
        vendor_set_id: user.id,
        warehouse_id: 1,
        description: 'ome',
        status_id: 1,
        current_document_no: docStaticCode,
        document_no: docStaticCode,
        model_id: 3,
        user_id: user.id,
        items: this.checkoutDetails.items,
      }).catch(error => error)
      // console.log(resultData)
      setTimeout(() => {
        if (resultData.data) {
          // this.$refs.formName.reset()
          // this.showError = false
          this.showToast('Notification', 'CheckCircleIcon', 'Sales Request saved successfully.👍', 'success')
          this.checkoutDetails.items.forEach(i => { this.removeProductFromCartClick(i) })
          this.$router.push({ name: 'apps-e-commerce-shop' })
        } else {
          this.showToast('Error', 'XCircleIcon', 'Sales Request Not saved', 'danger')
        }
        this.showOverLay = false
      }, 1000)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '~vue-form-wizard/dist/vue-form-wizard.min.css';
</style>

<style lang="scss" scoped>
.checkout-form-wizard ::v-deep {
  .wizard-tab-content {
    box-shadow: none !important;
    background: transparent !important;
    padding: 0;
  }
}
</style>
